import React from 'react';
import { graphql } from 'gatsby';
import BreadcrumbList from '../components/breadCrumbList.js'
import Seo from "../components/seo"

const WpPageTemplate = ({ data: { wpPage }, location }) => {
  const { title, content  } = wpPage;

  return (
    <>
      <Seo title={title} />
    <div id="pageTitle">
      <h1>{title}</h1>
    </div>
    <div className="breadClumb">
      <ul>
          <BreadcrumbList location={location}></BreadcrumbList>
      </ul>
    </div>
    <div id="main">
      <div className="contents">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  </>
  );
};

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`;

export default WpPageTemplate;